
import React, {useEffect, useState} from "react"

import Layout from "../../components/layout";
import SubmitDataField from "../../components/negosyonow/fields/submitdata";
import WebappForm from "../../components/webapps/form"

const negosyonowAPI = require("../../../lib/requestNegosyownow")

const ENTITYID="nnpayment"


const nnpaymentstatuslist = [
	{"value":1,"display":"Submitted / For Clearing"},
	{"value":2,"display":"Cancelled"},
	{"value":3,"display":"For Review / Checking"},
	{"value":4,"display":"Cleared"},
];


const searchFields = [
	{"label":"Last Update", "dbfield": "nnpayment_updated", "type": "datetime", "filtertype": "datetime"},
	{"label":"Payment Date", "dbfield": "nnpayment_date", "type": "datetime", "filtertype": "datetime"},
	{"label":"Validation Ref #", "dbfield": "nnpayment_validationref", "type": "text", "filtertype": "text"},
	{"label":"Status", "dbfield": "nnpaymentstatus_id", "type": "combo", "filtertype": "combo", "options": nnpaymentstatuslist},
	{"label":"Doc Type", "dbfield": "nnpayment_purpose", "type": "text", "filtertype": "text"},
	{"label":"Doc #", "dbfield": "nnpayment_purposerefnum", "type": "text", "filtertype": "text"},
	{"label":"Details", "dbfield": "nnpayment_notes", "type": "textarea", "filtertype": "text"},
	{"label":"Amount", "dbfield": "nnpayment_amount", "type": "currency", "filtertype": "currency"},
	{"label":"Status", "dbfield": "nnpaymentstatus_name", "type": "text", "filtertype": "text"},
	{"label":"Status Notes", "dbfield": "nnpayment_cancelreason", "type": "text", "filtertype": "text"},
];


const formFields = [
	[
		{"label":"Last Update", "field": "nnpayment_updated", "value": "", "input": "datetime", "mode": "readonly"},
		{"label":"Payment Date", "field": "nnpayment_date", "value": "", "input": "datetime", "mode": "readonly"},
		{"label":"Validation Ref #", "field": "nnpayment_validationref", "value": "", "input": "textbox", "mode": "readonly", "info":"Provided by payment clearing team like Onprem"},
		{"label":"Doc Type", "field": "nnpayment_purpose", "value": "", "input": "textbox", "mode": "readonly"},
		{"label":"Doc #", "field": "nnpayment_purposerefnum", "value": "", "input": "textbox", "mode": "readonly", "info":"Use as reference # in payment"},
		{"label":"Details", "field": "nnpayment_notes", "value": "", "input": "textarea", "mode": "readonly"},

		{"label":"Form Data", "field": "nnpayment_submiturl", "value": "", "input": "textarea", "mode": "readonly"},
		{"label":"Status", "field": "nnpaymentstatus_id", "value": "", "input": "combo", "mode": "readonly", "options": nnpaymentstatuslist},
		{"label":"Status Notes", "field": "nnpayment_cancelreason", "value": "", "input": "textarea", "mode": "readonly"},
		{"label":"Refnum", "field": "nnpayment_purposerefnum", "value": "", "input": "hidden", "mode": "readonly"},
	]
];

// TODO Subform

const PaymentsmasterlistPage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");
	const [userformurl, setUserformurl] = useState("");
	const [userformdata, setUserformdata] = useState({});


	useEffect(() => {
		function loadFormdata(formurl)
		{
			negosyonowAPI.loadFormData(formurl, token).then(dataresponse => {
				setUserformdata(dataresponse);
			});
		}

		loadFormdata(userformurl)
	}, [userformurl, token]);


	function customFieldFragment(inputlist, idx, isdisabled, fieldid, inputchangecallback)
	{
		if (inputlist[idx].field === "nnpayment_submiturl") {
			if (inputlist[idx].value) {
				if (inputlist[idx].value !== "") {
					setUserformurl(inputlist[idx].value);
					return displayFormData(inputlist[idx].label, userformdata);
				}
			} else {
				return <></>
			}
		}
		return null;
	}

	function displayFormData(title, formdata)
	{
		const labelformatlist = {
			"Date/Time of Deposit":"datetime",
			"Amount":"currency"
		};
		return <SubmitDataField
					datatitle={title}
					formdata={formdata}
					labelformatlist={labelformatlist}
				/>
	}


	return <Layout location={location}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}
		>
			<WebappForm
				pagetitle={pagetitle}
				allowadd={false}
				allowdelete={false}
				mobilerowtitlefield={["nnpayment_notes"]}
				searchFields={searchFields}
				editFields={formFields}
				userSearchParam={{"orderby":"nnpayment_updated desc"}}

				customFieldFragment={customFieldFragment}

				entity={ENTITYID}
				token={token} />
		</Layout>

}


export default PaymentsmasterlistPage;
